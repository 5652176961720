import React from 'react';
import { bool, string } from 'prop-types';
import loadable from '@loadable/component';

import useStyles from '../styles';

const LazyBanner = loadable(() => import('components/banners/in-app-banners/two'));
const Page = loadable(() => import('components/page'));
const ShareAndroidView = loadable(() => import('views/loyalty-card/share/android'));
const SkeletonShareView = loadable(() => import('views/loyalty-card/share/skeleton'));

const Typography = loadable(() => import('@material-ui/core/Typography'));
const Hidden = loadable(() => import('@material-ui/core/Hidden'));

const SuspendedShareAndroidPage = ({
  loading, passId = '',
  title = '', passToken = '',
}) => {
  const styles = useStyles();

  return (
      <Page
        title="Andoid"
        description={`Share your loyalty card: "${title}" with Android devices`}>
        <LazyBanner/>
        <Typography variant="h2" component="h1" className={styles.heading} align="center">
        Android Devices
        </Typography>
        <Hidden xsDown>
          <Typography variant="h4" component="p" className={styles.headingMessage} align="center">
           {`Share Loyalty Card: "${title}"`}
          </Typography>
        </Hidden>
        <Hidden smUp>
          <Typography variant="h4" component="p" className={styles.headingMessage} align="center">
           Share your loyalty card
           <br/>
           {`"${title}"`}
          </Typography>
        </Hidden>

        {loading ? (
          <SkeletonShareView />
        ) : (
          <div className={styles.content}>
            <ShareAndroidView
              passId={passId}
              title={title}
              token={passToken}
              />
          </div>
        )}
      </Page>
  );
};

SuspendedShareAndroidPage.propTypes = {
  loading: bool,
  passId: string,
  title: string,
  passToken: string,
};

export default SuspendedShareAndroidPage;
